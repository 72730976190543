<template>
  <div :class="`box_${$store.state.mode}`">
    <template v-if="$store.state.mode == 'pc'">
      <!-- <swiper key="openbox"></swiper> -->
      <div class="box_top">
        <div class="odds_readme" @click="handleReadme(true)"></div>
        <div class="box_name">
          <h2>{{ boxDetail.boxName }}</h2>
        </div>
        <div class="box_img">
          <img :class="['animate__animated', boxAnimate ? 'animate__rubberBand' : 'animate__tada']" :src="boxDetail.boxImg01" alt="" />
        </div>
        <div class="box_const">
         
          <price  :price="total" :size="$store.state.mode == 'mobile' ? 'small' : 'large'" />
        </div>
        <div class="box_tab">
          <div class="box_number">
            <div class="btn" v-for="item in boxNum" :key="item" :class="{ checked: data.num == item }" @click="handleChange(item)">
              {{ item }}
            </div>
          </div>
          <div class="box_switch">
            <div :class="{ active: !voice }" @click="handleVoice">
              <span>声音</span>
              <i class="el-icon-headset"></i>
            </div>
            <div :class="{ active: !animate }" @click="handleDraw">
              <span>动画</span>
              <i class="el-icon-help"></i>
            </div>
          </div>
        </div>
        <div class="box_open" @click="handleOpen">
          <div>打开</div>
        </div>
      </div>
      <div class="box_center">
        <div :class="['btn', { checked: type == 1 }]" @click="handleType(1)">玩家掉落</div>
        <div :class="['btn', { checked: type == 2 }]" @click="handleType(2)">盒子包含</div>
      </div>
      <div class="box_title">
        <lineTitle title="箱内物品概率" />
        <div class="level">
          <div class="levelItem" v-for="(item,i) in LevelList" :key="i">
            <div class="color" :style="{ backgroundColor: backgroundColor(item.level) }"></div>
            {{ item.probability }}
          </div>
        </div>
      </div>
      <div class="box_list" v-show="type == 1">
        <div class="box_bottom" v-if="boxHistory&&boxHistory.length">
          <goodsCard v-for="(item, index) in boxHistory" :key="index" :goodsItem="item" :keyMap="{ levelImg: 'ornamentLevelImg', name: 'ornamentName', price: 'staticPrice' }" />
        </div>
        <div class="not_data" v-else>--暂无数据--</div>
      </div>
      <div class="box_list" v-show="type == 2">
        <div class="box_bottom" v-if="boxDetail.boxOrnamentsList.length">
          <goodsCard v-for="(item, index) in boxDetail.boxOrnamentsList" :key="index" :goodsItem="item" :keyMap="{ price: 'staticPrice' }" />
        </div>
        <div class="not_data" v-else>--暂无数据--</div>
      </div>
      <!-- 掉落 -->
      <div class="result" v-if="isResult">
        <div class="result_content animate__animated animate__fadeInDown">
          <div class="result_center">
            <goodsCard v-for="(item, index) in resultList" :goodsItem="item" :key="index" :keyMap="{ name: 'ornamenName', price: 'staticPrice' }" />
          </div>
          <div class="result_btn">
            <div class="btn btn_click" @click="handleBag">放入背包</div>
            <div class="btn btn_click" @click="handleDecompose">
              一键分解  <price  :price="moneyAll" :size="$store.state.mode == 'mobile' ? 'small' : 'large'" />
            </div>
          </div>
        </div>
      </div>
      <!-- 概率说明 -->
      <el-dialog title="" :modal-append-to-body="false" append-to-body :show-close="false" class="readme_dialoag" :visible.sync="readmeVisible" :close-on-click-modal="false">
        <lineTitle title="概率说明" />
        <div class="cont">
          <p>1、宝箱内容与显示概率完全一致，用户可根据个人喜好选择相应的宝箱开启。</p>
          <p>2、下方的物品概率显示是可以看到当前箱子的物品等级整体概率。</p>
          <p>3、每次开启都属于独立事件，不受其他玩家和您之前开箱影响，多次开启不会增加概率。</p>
        </div>
        <div class="example">
          <h4>举例1:</h4>
          <p>箱子内A和B两个饰品概率各为50%，连续开启时，无法保证结果稳定是ABABAB。</p>
        </div>
        <div class="example">
          <h4>举例2:</h4>
          <p>某饰品概率为10%，并不是每开启10次就必出1次，可能20次才出1次，也可能10次出多次。</p>
        </div>
        <div class="close_btn" @click="handleReadme(false)">关闭</div>
      </el-dialog>
    </template>
    <template v-else>
      <div class="header_top">
        <div class="box_name">{{ boxDetail.boxName }}</div>
        <div class="speak space_between">
          <div class="drawV2 center btn_click" :class="animate ? '' : 'active_dV2'" @click="handleDraw">
            <svg t="1708582734439" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3632">
              <path
                d="M395.765333 586.570667h-171.733333c-22.421333 0-37.888-22.442667-29.909333-43.381334L364.768 95.274667A32 32 0 0 1 394.666667 74.666667h287.957333c22.72 0 38.208 23.018667 29.632 44.064l-99.36 243.882666h187.050667c27.509333 0 42.186667 32.426667 24.042666 53.098667l-458.602666 522.56c-22.293333 25.408-63.626667 3.392-54.976-29.28l85.354666-322.421333zM416.714667 138.666667L270.453333 522.581333h166.869334a32 32 0 0 1 30.933333 40.181334l-61.130667 230.954666 322.176-367.114666H565.312c-22.72 0-38.208-23.018667-29.632-44.064l99.36-243.882667H416.714667z"
                fill="#727477"
                p-id="3633"
              />
            </svg>
          </div>
          <div class="voiceV2 center btn_click" :class="voice ? '' : 'active_dV2'" @click="handleVoice">
            <svg t="1708582870392" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3952">
              <path
                d="M703.932266 193.305017l226.648416-107.435829a43.357257 43.357257 0 0 0-37.136528-78.347957l-251.430184 119.183687a43.350032 43.350032 0 0 0-24.781768 39.173978v324.229338a199.821971 199.821971 0 0 0-114.285134-35.792676c-110.824356 0-200.992422 90.160841-200.992422 200.992422 0 110.824356 90.160841 200.985197 200.992422 200.985197 110.824356 0 200.985197-90.160841 200.985198-200.985197V193.305017z m-200.992423 576.295322c-63.023721 0-114.292359-51.268638-114.292358-114.285134s51.268638-114.285134 114.292358-114.285134S617.232202 592.291484 617.232202 655.30798s-51.268638 114.292359-114.292359 114.292359zM319.590884 479.480251c16.473012-7.138305 24.037593-26.284569 16.892062-42.757581s-26.284569-24.044818-42.757581-16.892063c-44.657758 19.363014-79.106583 54.95339-96.988471 100.232499-17.889113 45.279108-17.072688 94.806519 2.297552 139.464277a32.526974 32.526974 0 0 0 42.764806 16.899287 32.512524 32.512524 0 0 0 16.892062-42.764806 116.467085 116.467085 0 0 1-1.473901-89.705666 116.365935 116.365935 0 0 1 62.373471-64.475947zM214.192507 374.544274a32.512524 32.512524 0 0 0-25.872744-59.656868C50.416087 374.703224-13.127835 535.560742 46.673534 673.471643a32.526974 32.526974 0 0 0 42.764807 16.899288 32.512524 32.512524 0 0 0 16.892062-42.764807c-45.539208-105.015452 2.846652-227.508192 107.862104-273.06185zM832.46511 663.739561a32.512524 32.512524 0 0 0-61.441445 21.313766c21.183716 61.065745-11.263783 127.976519-72.322303 149.167459a32.512524 32.512524 0 1 0 21.313765 61.426995c94.929345-32.938799 145.374331-136.97165 112.449983-231.90822zM981.032894 670.357666a32.526974 32.526974 0 0 0-61.441445 21.313766c18.178113 52.381288 14.861836 108.714655-9.334707 158.610541s-66.368899 87.386439-118.757412 105.550102a32.512524 32.512524 0 1 0 21.313765 61.441445c68.7965-23.864192 124.183391-73.095379 155.95174-138.633401 31.768348-65.516348 36.125026-139.485952 12.268059-208.282453z"
                fill="#727477"
                p-id="3953"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="box_top">
        <div class="box_list">
          <div class="box_list_i" v-for="item in 5" :key="item">
            <svg t="1705892723930" :class="['icon box_list_i_i', { 'animate__animated animate__flipInX active': data.num > item - 1 }]" viewBox="0 0 1024 1024" version="1.1" p-id="2819" id="mx_n_1705892723931" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M544 18.496l375.04 216.512-76.352 44.16a56.832 56.832 0 0 0-24 71.168l3.2 6.4a56.832 56.832 0 0 0 71.168 24l6.4-3.2 55.936-32.32v385.856a64 64 0 0 1-32 55.424L576 987.008V896a64 64 0 0 0-56.512-63.552L512 832a64 64 0 0 0-63.552 56.512L448 896v91.008L100.608 786.496a64 64 0 0 1-32-55.424V328.128l85.44 49.408a56.832 56.832 0 0 0 73.6-14.848l3.968-5.952a56.832 56.832 0 0 0-14.848-73.6l-5.952-3.968L119.68 226.496 480 18.496a64 64 0 0 1 64 0zM511.744 678.4a40.768 40.768 0 0 0-30.272 12.48 43.328 43.328 0 0 0-12.16 32c0 13.056 3.84 23.744 12.16 32.064 8.256 8.32 18.112 13.056 30.272 13.056 12.096 0 22.016-4.16 30.272-12.48a45.12 45.12 0 0 0 12.672-32.64 45.632 45.632 0 0 0-12.16-32 41.984 41.984 0 0 0-30.784-12.48z m6.4-358.4c-41.344 0-73.792 12.48-97.792 37.44-24.64 24.96-36.352 59.392-36.352 103.296h63.744c0-24.96 4.48-44.544 13.952-58.176 10.624-16.64 27.968-24.32 52.48-24.32 19.072 0 34.176 5.312 44.8 16.64 10.048 11.264 15.616 26.688 15.616 46.272 0 14.848-4.992 29.12-15.104 42.24l-6.656 8.256c-36.352 34.432-58.176 59.392-65.408 75.456-7.872 16-11.2 35.584-11.2 58.24V633.6h64.256v-8.32c0-14.272 2.816-26.752 8.384-38.592 5.056-10.688 12.352-20.8 22.4-29.696 26.816-24.96 43.008-40.96 48-46.912 13.44-19.008 20.736-43.392 20.736-73.088 0-36.224-11.2-64.704-33.536-85.504C584.064 330.112 554.496 320 518.144 320z"
                fill="#323436"
                p-id="2820"
              />
            </svg>
          </div>
        </div>
        <div class="box_number">
          <div class="btn_click btn" v-for="item in 5" :key="item" :class="{ active_n: data.num == item }" @click="handleChange(item)">x{{ item }}</div>
          <div ref="slider" class="slider" :style="{ left: left + 'px' }"></div>
        </div>
        <div class="box_const">
          
          <price  :price="total" :size="$store.state.mode == 'mobile' ? 'small' : 'large'" />
        </div>
        <div class="box_title">
          <lineTitle title="箱内物品概率" />
          <div class="level">
            <div class="levelItem" v-for="(item,i) in LevelList" :key="i">
              <div class="color" :style="{ backgroundColor: backgroundColor(item.level) }"></div>
              {{ item.probability }}
            </div>
          </div>
        </div>
        <div class="btn_click box_open" @click="handleOpen">
          <div>立即开启</div>
        </div>
      </div>
      <!-- 掉落 -->
      <alert class="result_alert" :visible="isResult" title="恭喜获得" @cancel="handleBag" cancelText="放入背包" confirmText="全部分解" @confirm="handleDecompose">
        <div class="result_center">
          <goodsCard v-for="(item, index) in resultList" :key="index" :goodsItem="item" :keyMap="{ price: 'staticPrice', name: 'ornamenName' }">
            <div class="button" @click="handleD(item.id, index)">分解物品</div>
          </goodsCard>
        </div>
        <div class="result_money">
          <price :price="moneyAll" size="middle" />
        </div>
      </alert>
      <div class="box_center">
        <div class="btn_click" :class="type == 1 ? 'active_d' : ''" @click="handleType(1)">会员掉落</div>
        <div class="btn_click" :class="type == 2 ? 'active_d' : ''" @click="handleType(2)">盒子包含</div>
      </div>
      <div class="box_bottom" v-if="type == 1">
        <div class="goods_list" v-if="boxHistory&&boxHistory.length">
          <goodsCard v-for="(item, index) in boxHistory" :key="index" :goodsItem="item" :keyMap="{ levelImg: 'ornamentLevelImg', name: 'ornamentName', price: 'staticPrice' }" />
        </div>
        <div class="not_data" v-else>--暂无数据--</div>
      </div>
      <div class="box_bottom" v-if="type == 2">
        <div class="goods_list" v-if="boxDetail.boxOrnamentsList.length">
          <goodsCard v-for="(item, index) in boxDetail.boxOrnamentsList" :key="index" :goodsItem="item" :keyMap="{ price: 'staticPrice' }" />
        </div>
        <div class="not_data" v-else>--暂无数据--</div>
      </div>
    </template>
    <!-- 开箱 -->
    <div :class="['cartoon_bg', { close: !isOpen }]">
      <div :class="['cartoon_colum', { active: isOpen }]">
        <div class="cartoon_list">
          <template v-for="num in boxNum">
            <div :key="num" v-if="data.num >= num" :style="{ width: $store.state.mode == 'mobile' ? `20%` : '20%' }" :class="[isanimate ? (num % 2 ? 'cartoon_list_animate' : 'cartoon_list_animate1') : '']">
              <div class="goos_box" v-for="(item, index) in getWeapons(num)" :key="index">
                <goodsCard :showOdd="false" :goodsItem="item" :keyMap="{ price: 'staticPrice', name: 'ornamenName' }" />
              </div>
            </div>
          </template>
        </div>
        <!-- 横行 -->
        <div class="hengxianl"></div>
        <div class="heng_xian"></div>
        <div class="hengxianr"></div>
      </div>
    </div>
  </div>
</template>

<script>
// import swiper from '@/components/swiperUser.vue';
import lineTitle from '@/components/lineTitle.vue';
import goodsCard from '@/components/goodsCard';

import { getUserInfo, getDetail, openBox, decompose } from '@/api/index.js';
import Socket from '@/utils/socket';

export default {
  data() {
    return {
      isOpen: false,
      boxNum: 5,
      isanimate: null,
      isResult: false,
      voice: false,
      animate: false,
      readmeVisible: false,
      data: {
        id: this.$route.query.id,
        num: 1,
      },
      history: {
        boxId: this.$route.query.id,
        page: 1,
        size: 9,
        orderByFie: 0,
        status: [0, 2, 3, 5],
        source: [1],
      },
      weaponList1: [],
      weaponList2: [],
      weaponList4: [],
      weaponList3: [],
      weaponList5: [],
      boxDetail: { boxName: '', boxOrnamentsList: [], boxImg01: '' },
      boxHistory: null,
      resultList: [],
      total: 0,
      type: 1,
      left: 2,
      voicefile: new Audio(),
      openAudio: new Audio(),
      totalNum: 9,
      boxAnimate: false,
      LevelList: [],
    };
  },
  components: {
    lineTitle,
    goodsCard,
  },
  created() {
    this.queryDetail();
  },
  mounted() {
    this.connectWs();
    this.voicefile.src = require('../../../../public/2.mp3');
    this.openAudio.src = require('../../../../public/01.mp3');
    let timer = setTimeout(() => {
      const mainDom = document.getElementById('main');
      clearTimeout(timer);
      if (!mainDom) return;
      mainDom.scrollTo(0, 0);
    }, 100);
  },
  beforeRouteLeave(to, from, next) {
    this.$socket?.close();
    next();
  },
  computed: {
    // 统计金币
    moneyAll() {
      let bean = 0;
      this.resultList.forEach(item => {
        bean += item.staticPrice * 1;
      });
      return bean.toFixed(2);
    },
  },
  methods: {
    backgroundColor(level) {
      if (level == "金色") {
        return "#f9c328";
      } else if (level == "红色") {
        return "#e43e30";
      } else if (level == "紫色") {
        // return "#460f8d";
        return "#620b9a";
      } else if (level == "蓝色") {
        return "#0a86d9";
      } else if (level == "灰色") {
        return "#807d7d";
      }
    },
    handleReadme(bool) {
      this.readmeVisible = bool;
      this.playAudio();
    },
    getWeapons(num) {
      return this[`weaponList${num}`];
    },
    playAudio() {
      this.$store.commit('playAudio');
    },
    connectWs() {
      getUserInfo().then(res => {
        let bid = this.$route.query.id;
        let uid = res?.data?.data?.userId;

        this.$socket = new Socket({
          url: '/ws/blindBox/' + bid + '/' + uid + '/' + 21,
          watchLogin: true,
          onmessage: msg => {
            if (!Array.isArray(msg.data)) return;
            if (this.boxHistory==null) {
              console.log(111);
              this.boxHistory=msg.data;
              return;
            }
            let timer = setTimeout(() => {
              this.boxHistory.unshift(...msg.data);
              if (this.boxHistory.length >= 21) {
                // console.log('末尾删除', this.boxHistory.length, msg.data.length, msg.data.length);
                this.boxHistory.splice(this.boxHistory.length - msg.data.length, msg.data.length);
              }
              clearTimeout(timer);
            }, 5500);
          },
        });
      });
    },
    handleVoice() {
      this.voice = !this.voice;
      this.playAudio();
    },
    handleDraw() {
      this.animate = !this.animate;
      this.playAudio();
    },
    reset() {
      this.isanimate = null;
      this.isOpen = false;
    },
    handleD(res, index) {
      this.resultList.splice(index, 1);
      decompose({ isAll: false, packSackIds: [res] }).then(res => {
        getUserInfo().then(res => {
          this.user = res?.data?.data || {};
          this.$store.commit('USER_INFO', this.user);
        });
        this.$message({
          message: res?.data?.msg,
          type: 'success',
        });
      });
      if (this.resultList.length == 0) {
        this.isResult = false;
        this.reset();
      }
    },
    handleDecompose() {
      this.playAudio();
      let arr = [];
      this.resultList.forEach(i => {
        console.log(i);
        arr.push(i.id);
      });
      decompose({ isAll: false, packSackIds: arr }).then(res => {
        this.isResult = false;
        this.$message({
          message: res?.data?.msg,
          type: 'success',
        });
        getUserInfo().then(res => {
          this.user = res?.data?.data || {};
          this.$store.commit('USER_INFO', this.user);
        });
        this.reset();
      });
    },
    handleBag() {
      this.playAudio();
      this.isResult = false;
      this.resultList = [];
      this.reset();
    },
    handleOpen() {
      this.playAudio();
      if (this.$store.state.LOGIN_IS_SHOW) {
        this.$router.push('/login');
        return;
      }
      if (this.$store.state.USER_INFO.accountAmount + this.$store.state.USER_INFO.accountCredits < this.total) {
        this.$message({
          message: '余额不足，请充值',
          type: 'warning',
        });
        return false;
      }
      this.getweapon();

      let obj = {};
      obj.boxId = this.data.id;
      obj.num = this.data.num;
      let timer = setTimeout(() => {
        openBox(obj).then(res => {
          if (res?.data?.code == 200) {
            const data = res?.data?.data || [];
            this.resultList = data;
            this.weaponList1.push(data[0]);
            this.weaponList2.push(data[1]);
            this.weaponList3.push(data[2]);
            this.weaponList4.push(data[3]);
            this.weaponList5.push(data[4]);

            if (this.animate == false) {
              let timer = setTimeout(() => {
                this.isResult = true;
                if (!this.voice) this.openAudio.play();
                clearTimeout(timer);
              }, 6000);
            } else {
              this.isResult = true;
            }
            if (this.animate == false) {
              if (!this.voice) {
                this.voicefile.play().then(() => {
                  this.isOpen = true;
                  let timer = setTimeout(() => {
                    this.isanimate = true;
                    clearTimeout(timer);
                  }, 5);
                });
              } else {
                this.isOpen = true;
                let timer = setTimeout(() => {
                  this.isanimate = true;
                  clearTimeout(timer);
                }, 5);
              }
            } else {
              this.isanimate = false;
            }
            getUserInfo().then(res => {
              this.user = res?.data?.data || {};
              this.$store.commit('USER_INFO', this.user);
            });
          } else if (res?.data?.code == 500) {
            this.isOpen = false;
            this.$message({
              type: 'warning',
              message: res.data.msg,
            });
          }
          // console.log("1111", res?.data?.data);
        });
        clearTimeout(timer);
      }, 200);
    },
    handleType(type) {
      this.type = type;
      this.playAudio();
    },
    handleChange(res) {
      this.boxAnimate = !this.boxAnimate;
      this.playAudio();
      this.getweapon();
      this.data.num = res;
      const numall = parseFloat(this.boxDetail?.price * res);
      this.total = Math.round(numall * 100) / 100;

      if (this.$mode == 'pc') return;
      const width = this.$refs.slider?.offsetWidth;
      this.left = (res - 1) * width + 2;
    },
    arraySort(arr) {
      arr = arr.sort((a, b) => b.staticPrice - a.staticPrice);
      return arr;
    },
    //箱子包含
    getweapon() {
      this.weaponList1 = [];
      this.weaponList2 = [];
      this.weaponList3 = [];
      this.weaponList4 = [];
      this.weaponList5 = [];
      for (let i = 0; i < 60; i++) {
        this.weaponList1.push(this.weaponList[Math.floor(Math.random() * this.weaponList.length)]);
        this.weaponList2.push(this.weaponList[Math.floor(Math.random() * this.weaponList.length)]);
        this.weaponList3.push(this.weaponList[Math.floor(Math.random() * this.weaponList.length)]);
        this.weaponList4.push(this.weaponList[Math.floor(Math.random() * this.weaponList.length)]);
        this.weaponList5.push(this.weaponList[Math.floor(Math.random() * this.weaponList.length)]);
      }
    },
    //生成武器列表
    queryDetail() {
      getDetail(this.data.id).then(res => {
        if (!res?.data?.data) return;
        this.boxDetail = res?.data?.data || {};
        this.total = this.boxDetail?.price || 0;
        this.weaponList = this.arraySort(this.boxDetail.boxOrnamentsList);
        this.LevelList = res.data.data.probabilityDistribution.sort((a, b) => {
          return a.ornamentsLevelId - b.ornamentsLevelId;
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.box_pc {
  .box_top {
    overflow: hidden;
    margin-top: 10px;
    position: relative;
    background: url(../../../assets/image/openboxbg.png) no-repeat;
    background-size: 100% 100%;
    .odds_readme {
      top: 90px;
      opacity: 0;
      right: 4%;
      width: 10%;
      height: 40px;
      position: absolute;
    }
    .box_name {
      margin-top: 108px;
      text-align: center;
      .flex-a-center;
      h2 {
        flex: 1;
        .sc(30px, #fff);
      }
    }
    .box_img {
      .wh(100%, 250px);
      .flex-center;
      margin: 80px 0 50px;
      img {
        .wh(auto, 100%);
      }
    }
    .box_const {
      .flex-center;
      span {
        .sc(20px, #fff);
      }
    }
    .box_tab {
      .flex-center;
      margin-top: 15px;
      .box_number {
        border-radius: 10px;
        display: inline-flex;
        align-items: center;
        margin: 0 10px 0 80px;
        border: 1px solid @bd2;
        .btn {
          .wh(58px, 48px);
          .sc(20px, #fff);
          text-align: center;
          line-height: 48px;
          border-right: 1px solid @bd2;
          &:first-of-type {
            border-radius: 10px 0 0 10px;
          }
          &:last-of-type {
            border-right: none;
            border-radius: 0 10px 10px 0;
          }
        }
      }
      .box_switch {
        div {
          .sc(16px, #fff);
          margin-left: 10px;
          span {
            padding-right: 5px;
          }
        }
        .active {
          color: @main;
        }
      }
    }
    .box_open {
      .wh(210px, 60px);
      .sc(24px, #fff);
      text-align: center;
      line-height: 60px;
      font-weight: bold;
      margin: 35px auto 70px;
      background: url(../../../assets/image/loginbtn.png) no-repeat;
      background-size: 100% 100%;
    }
  }
  .box_center {
    .flex-center;
    z-index: 2;
    position: relative;
    margin-bottom: 60px;
    .btn {
      .wh(160px, 42px);
      .sc(18px, #fff);
      margin: 0 24px;
      line-height: 42px;
      text-align: center;
      border-radius: 21px;
      border: 1px solid @bd2;
    }
  }
  .checked {
    border: none;
    box-shadow: 0 0 6px 2px @main;
  }
  .box_title {
    margin: 70px auto 50px;
    .level {
      display: flex;
      color: white;
      width: 40%;
      margin: 20px auto 0;
      // font-size: 20rem;

      .levelItem {
        margin-right: 20px;
        position: relative;
        padding-left: 40px;
      }

      .color {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        // top: 5px;
        left: 12px;
      }
    }
  }
  .box_bottom {
    .grid(7, 10px);
    margin: 0 @pcSpace 0;
    &:last-of-type {
      margin-bottom: 100px;
    }
  }
  .result {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 31;
    position: fixed;
    background: rgba(0, 0, 0, 0.6);
    .flex-center;
    .result_content {
      opacity: 1;
      .result_center {
        .flex-center;
        & > div {
          margin: 5px;
          width: 180px;
        }
        @media @max750 {
          margin: 0 10px;
          flex-wrap: wrap;
        }
      }
      .result_btn {
        width: 100%;
        font-size: 21px;
        margin-top: 35px;
        .flex-center;
        .btn {
          .sc(18px, #fff);
          .flex-center;
          height: 42px;
          margin: 0 15px;
          padding: 0 10px;
          min-width: 150px;
          border-radius: 21px;
          &:first-of-type {
            border: none;
            background: #042124;
            .btn-shadow;
          }
          &:last-of-type {
            background: @bg2;
            border: 1px solid @bd2;
          }
          .money {
            margin: 0 4px 0 10px;
          }
        }
      }
    }
  }
}
.cartoon_bg {
  opacity: 1;
  z-index: 20;
  .fixed_bg;
  .flex-center;
  @media @max750 {
    width: 100%;
  }
  .active {
    animation: hengxian 0.5s linear;
  }
  .cartoon_colum {
    .wh(60%, 210px);
    padding: 5px 10px;
    margin: 0 auto;
    overflow: hidden;
    background: #000;
    border-radius: 14px;
    position: relative;
    @media @max750 {
      padding: 6px;
      .wh(92%, 150px);
      border-radius: 10px;
    }
  }
  @keyframes hengxian {
    0% {
      width: 0%;
    }
    52% {
      width: 30%;
    }
    100% {
      width: 60%;
    }
  }

  .cartoon_list {
    display: flex;
    overflow: hidden;
    justify-content: center;
    & > div {
      margin: 0 6px;
      @media @max750 {
        margin: 0 2px;
      }
    }
    .goos_box {
      height: 210px;
      overflow: hidden;
      @media @max750 {
        height: 150px;
      }
    }
    .goods_item {
      height: 200px;
      margin: 5px 0;
      .flex-column;
      @media @max750 {
        height: 140px;
        border-radius: 6px;
        ::v-deep .goods_bottom {
          height: 40px;
          padding: 2px 0;
          .flex-column-center;
          border-radius: 0 0 6px 6px;
          .goods_name {
            font-size: 12px;
            padding-bottom: 0;
          }
        }
      }
      ::v-deep .goods_img {
        flex: 1;
        width: 100%;
      }
    }
  }
  .cartoon_list_animate {
    transition: all 5.5s cubic-bezier(0, 0.2, 0, 1) 0s;
    transform: translateY(-12600px);
    @media @max750 {
      transform: translateY(-9000px);
      // transform: translateY(-7200px);
    }
  }
  .cartoon_list_animate1 {
    transition: all 5.5s cubic-bezier(0, 0.3, 0, 1) 0s;
    transform: translateY(-12600px);
    @media @max750 {
      transform: translateY(-9000px);
    }
  }
  .heng_xian {
    left: 0;
    top: 50%;
    width: 100%;
    height: 3px;
    opacity: 0.87;
    z-index: 9999;
    margin: 0 auto;
    margin-top: -1.5px;
    position: absolute;
    background-color: @main;
    &::after,
    &::before {
      position: absolute;
      top: -50%;
      margin-top: -6px;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      border-width: 9px;
    }
    &::before {
      left: 0;
      border-left-color: @main;
    }
    &::after {
      right: 0;
      border-right-color: @main;
    }
  }
  &.close {
    opacity: 0 !important;
    left: 100% !important;
  }
}
.box_mobile {
  left: 0;
  right: 0;
  top: @headerH;
  bottom: @footerH;
  padding: 0 @mSpace;
  position: absolute;
  background: #181a1c;
  .flex-column;
  ::v-deep .yy {
    .el-switch__core {
      border: 3px solid #727477;
      background: none;
    }
    .el-switch__core:after {
      width: 12px;
      height: 12px;
      background-color: @main;
    }
    &.el-switch.is-checked .el-switch__core::after {
      background-color: #727477;
      margin-left: -14px;
    }
  }
  ::v-deep .dh {
    .el-switch__core {
      background: none;
    }
    .el-switch__core {
      border: 3px solid #727477;
      background: none;
    }
    .el-switch__core:after {
      width: 12px;
      height: 12px;
      background-color: @main;
    }
    &.el-switch.is-checked .el-switch__core::after {
      background-color: #727477;
      margin-left: -14px;
    }
  }
  .drawV2,
  .voiceV2 {
    width: 35px;
    height: 35px;
    margin-left: 4px;
    @media @max750 {
      width: 25px;
      height: 25px;
    }
    border-radius: 7px 7px 7px 7px;
    opacity: 1;
    border: 1px solid #727477;
    .icon {
      width: 20px;
      height: 21px;
      @media @max750 {
        width: 14px;
        height: 15px;
      }
    }
  }
  .active_dV2,
  .active_vV2 {
    border: 1px solid @main;
    transition: all 0.3s ease-in-out;
    svg {
      path {
        fill: @main;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .button {
    margin: 0 auto;
    margin-top: 4px;
    text-align: center;
    .sc(14px, @main);
  }
  .result_alert {
    ::v-deep .alert_content {
      .result_center {
        .flex-center;
        width: 100%;
        flex-wrap: wrap;
        .goods_item {
          margin: 4px;
          width: 40%;
        }
      }
      .result_money {
        width: 90%;
        height: 40px;
        .flex-center;
        margin: 0 auto;
        margin-top: 14px;
        border-radius: 35px;
        background: #323436;
      }
    }
  }
  .box_title {
    margin: 20px auto 30px;
    .level {
      display: flex;
      color: white;
      width: 60%;
      margin: 20px auto 0;
      // justify-content: center;
      align-items: center;
      // font-size: 20rem;

      .levelItem {
        margin-right: 20px;
        position: relative;
        padding-left: 40px;
      }

      .color {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: absolute;
        // top: 3px;
        left: 12px;
      }
    }
  }
  .box_bottom {
    flex: 1;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    .goods_list {
      .grid(3, 6px);
      padding-bottom: 10px;
    }
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background: @main;
      border-radius: 5px;
    }
  }
  .box_center {
    .flex-center;
    border-radius: 70px;
    background: #323436;
    color: #727477;
    width: 180px;
    height: 30px;
    margin: 14px auto;
    font-size: 14px;
    div {
      width: 49%;
      height: 100%;
      border-radius: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .active_d {
      border: 1px solid @main;
      color: @main;
    }
  }

  .box_open {
    .btn-bg;
    .flex-center;
    height: 40px;
    font-size: 18px;
    margin: 0 auto;
    margin-top: 14px;
  }
  .box_const {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    margin: 0 auto;
    color: #25f484;
    margin-top: 14px;
  }
  .box_number {
    background: #323436;
    border-radius: 70px;
    opacity: 1;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #727477;
    transition: all 0.2s ease-in-out;

    font-weight: bold;
    position: relative;
    margin-top: 10px;
    width: 305px;
    height: 30px;
    font-size: 13px;
    .btn {
      width: 84px;
      height: 35px;
      opacity: 1;
      color: #727477;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 2;
    }
    .active_n {
      color: @main;
      transition: all 0.2s ease-in-out;
    }
    .slider {
      position: absolute;
      transition: left 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      width: 60px;
      height: 25px;
      border-radius: 50px;

      border: 1px solid @main;
      box-sizing: border-box;
    }
  }
  .box_list {
    width: 100%;
    background: #101115;
    border-radius: 14px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 80px;
  }
  .box_list_i {
    width: 57px;
    height: 61px;
  }
  .cartoon_d {
    width: 57px;
    height: 61px;
    border-radius: 7px 7px 7px 7px;
    overflow: hidden;
  }
  .box_list_i_i {
    width: 57px;
    height: 57px;
    transition: all 0.3s;
    &.active {
      path {
        fill: @main;
      }
    }
  }
  .box_switch {
    margin-top: 14px;
    display: flex;
    align-items: center;
    justify-content: right;
    display: flex;
    align-items: center;
    div {
      color: #727477;
      margin-right: 5px;
    }
  }
  .header_top {
    width: 100%;
    height: 56px;
    line-height: 56px;
    position: relative;
    .box_name {
      .sc(18px, #fff);
      text-align: center;
    }
    .speak {
      .abs-ct;
      right: 0;
    }
  }
}
</style>

<style lang="less">
.readme_dialoag {
  .flex-center;
  .el-dialog {
    margin: 0;
    padding: 0;
    width: auto;
    max-width: 576px;
    display: inline-block;
    border-radius: 20px;
    margin: -50px 20px 0 !important;
    background: url(../../../assets/image/readmebg.png) no-repeat;
    background-size: 100% 100%;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 50px 40px;
      .line_title .text {
        font-size: 24px;
      }
      .cont {
        padding: 40px 0 35px;
        border-bottom: 1px solid @bd1;
        p {
          .sc(16px, #DBDBDB);
          line-height: 24px;
          margin-bottom: 24px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .example {
        margin-top: 35px;
        h4 {
          .sc(16px, @main);
          font-weight: normal;
        }
        p {
          margin-top: 10px;
          line-height: 24px;
          .sc(16px, #DBDBDB);
        }
      }
      .close_btn {
        .wh(140px, 40px);
        .sc(18px, #fff);
        text-align: center;
        line-height: 40px;
        border-radius: 20px;
        margin: 100px auto 0;
        background: #19373a;
        .btn-shadow;
      }
    }
  }
}
</style>
